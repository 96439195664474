export const qoutes = [
  `The authenticity of love and passion is proven through time, sacrifice, and consistency.`,
  `No shame in falling; rise quickly not to remain there and move forward.`,
  `In the stories of great success, don't remain only as a storyteller. Own part of the stories.`,
  `Don't dismiss others' possibilities based on your experiences. Success involves a mix of factors, including luck, effort, and timing.`,
  `In public conflicts, maintain composure, lock eyes in silence, and gracefully walk away. Your composed response earns respect and highlights the other person's folly`,
  `Don't assume what didn't work for you won't work for someone else. Avoid projecting your failures onto others.`,
  `Offer second chances generously. Forgive a third time. If they persist, it's not a mistake but a glimpse of their true character.`,
  `Listen more, talk less. Give others the space to share, and learn from what they have to say.`,
  `Never criticize those on their journey, especially when you're still finding your own way.`,
  `Choose your 'Yes' carefully; sometimes, saying 'No' elevates your status.`,
];
