import "./Education.scss";

const Education = () => {
  return (
    <section className="education" id="education" data-aos="zoom-in">
      <h1>Education</h1>
      <p>
        Below contains highlights of my academic experience and qualifications
      </p>
      <div className="education__timeline">
        <h2>Bachelor of Technology - Computer Science</h2>
        <p>Computer Science (Cybersecurity) - Second Upper Grade</p>
        <h3>Faculty Software Director</h3>
        <p>
          - Served as the director of software for of a faculty of over 3,000
          students
        </p>
        <h3>University Programming Contest - Silver Medal Team</h3>
        <p>
          - Came second for the university in a team of 3 amongst 16 other
          universities at the Nigerian University Collegiate Programming Contest
          (NUCPC)
        </p>
        <h3>National Blockchain Hackathon - Gold Medal Team</h3>
        <p>
          - A student coordinator of the winning University team at the National
          Blockchain Hackathon
        </p>
        <h3>University Award of Productivity & Excellence</h3>
        <p>- Received an award of excellence from the University</p>
        <h3>Letter of Honorary from the Vice chancellor</h3>
        <p>
          - Received a Honorary letter from the University's Vice chancellor
        </p>
      </div>
    </section>
  );
};

export default Education;
